<template>
  <v-container class="pa-0">
    <v-row justify="center" class="py-5">
      <v-expansion-panels accordion flat>
        <v-expansion-panel v-for="(item, i) in items" :key="i" role="button">
          <v-expansion-panel-header @click="setSelectedPanel(item.name)">
            <v-icon v-if="selectedPanel !== item.name" color="#1E853A" left
              >mdi-check-circle</v-icon
            ><span class="subtitle-1 font-weight-medium">{{
              $t(item.name)
            }}</span>
            <template v-slot:actions>
              <div
                class="item-selected-text grey--text text--darken-1 body-2 pb-1"
              >
                {{
                  `${item.activeNodes.length}/${item.totalItems} ${$t(
                    "selected",
                  )}`
                }}
              </div>
              <v-icon> $expand </v-icon>
            </template></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-treeview
              :ref="`treeView${item.name}`"
              selectable
              open-all
              :disabled="defaultRoles.includes(role)"
              dense
              :value="item.activeNodes"
              item-disabled="locked"
              :items="item.items"
              @input="updateNodes"
            >
            </v-treeview>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedPanel: "",
      defaultRoles: rolesAndPermissionsDefaults.defaultRoles,
    };
  },
  methods: {
    setSelectedPanel(panelName) {
      this.selectedPanel = this.selectedPanel !== panelName ? panelName : "";
    },

    updateNodes(nodes) {
      const currentItem = this.items.find(
        (item) => item.name === this.selectedPanel,
      );
      currentItem.activeNodes = nodes;
      const otherItemActiveNodes = [];
      this.items
        .filter((item) => item.name !== this.selectedPanel)
        .forEach((item) =>
          otherItemActiveNodes.push(
            ...this.getActiveNodes(item, item.activeNodes),
          ),
        );
      const activeNodes = [
        ...this.getActiveNodes(currentItem, nodes),
        ...otherItemActiveNodes,
      ];
      const updateObject = {
        role: this.role,
        group: this.selectedPanel,
        activeNodes: activeNodes,
      };
      this.$emit("updatePermissions", updateObject);
    },

    getActiveNodes(treeGroup, nodes) {
      const activeNodes = [];
      treeGroup.items.forEach((element) => {
        if (nodes.includes(element.id)) {
          activeNodes.push(element.key);
        }
        if (element.permissions && element.permissions.length) {
          element.children.forEach((item) => {
            if (nodes.includes(item.id)) {
              activeNodes.push(item.key);
            }
          });
        }
      });
      return activeNodes;
    },
  },
};
</script>

<style scoped lang="scss">
.v-expansion-panel {
  border-bottom: 1px solid #e0e0e0;
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}
.item-selected-text {
  position: relative;
  right: 30px;
  top: 2px;
}
</style>
