var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { staticClass: "py-5", attrs: { justify: "center" } },
        [
          _c(
            "v-expansion-panels",
            { attrs: { accordion: "", flat: "" } },
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "v-expansion-panel",
                { key: i, attrs: { role: "button" } },
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setSelectedPanel(item.name)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-selected-text grey--text text--darken-1 body-2 pb-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.activeNodes.length +
                                            "/" +
                                            item.totalItems +
                                            " " +
                                            _vm.$t("selected")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("v-icon", [_vm._v(" $expand ")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.selectedPanel !== item.name
                        ? _c(
                            "v-icon",
                            { attrs: { color: "#1E853A", left: "" } },
                            [_vm._v("mdi-check-circle")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "subtitle-1 font-weight-medium" },
                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-treeview", {
                        ref: "treeView" + item.name,
                        refInFor: true,
                        attrs: {
                          selectable: "",
                          "open-all": "",
                          disabled: _vm.defaultRoles.includes(_vm.role),
                          dense: "",
                          value: item.activeNodes,
                          "item-disabled": "locked",
                          items: item.items,
                        },
                        on: { input: _vm.updateNodes },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }